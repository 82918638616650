.ProseMirror {
    .is-empty::before {
        @apply text-blue-500 dark:text-blue-400;
    }

    & > *:first-child,
    [data-type='column'] > * {
        @apply first:mt-0 last:mb-0;
    }

    & > * + * {
        margin-top: 0.75em;
    }

    .node-imageUpload {
        @apply rounded border-2 border-dotted border-black border-opacity-10 p-2 dark:border-blue-500;
        transition: border 160ms cubic-bezier(0.45, 0.05, 0.55, 0.95);

        &:hover {
            @apply border-opacity-30;
        }

        &:has(.is-active),
        &.has-focus {
            @apply border-opacity-40;
        }
    }

    [data-type='columns'] {
        &.has-focus [data-type='column'],
        &:hover [data-type='column'] {
            @apply border-blue-300 dark:border-blue-700;
        }

        [data-type='column'].has-focus {
            @apply border-blue-400 dark:border-blue-600;
        }
    }

    [data-type='column'] {
        @apply rounded border-2 border-dotted border-transparent p-1;
        transition: border 160ms cubic-bezier(0.45, 0.05, 0.55, 0.95);

        &:hover {
            @apply border-blue-100 dark:border-blue-900;
        }

        &:has(.is-active),
        &.has-focus {
            @apply border-blue-100 dark:border-blue-900;
        }
    }

    .node-imageBlock {
        & img {
            @apply border-2 border-transparent rounded-xl overflow-hidden;
        }

        &:hover img {
            @apply border-2 border-blue-100 dark:border-blue-900;
        }

        &:has(.is-active) img,
        &.has-focus img {
            @apply border-2 border-blue-800 dark:border-blue-200;
        }
    }

    .node-aiWriter,
    .node-aiImage,
    .node-tableOfContentsNode {
        &.has-focus [data-node-view-wrapper] > * {
            @apply shadow-[0_0_0_2px] shadow-black/30 dark:shadow-white/30 transition-all rounded-lg;
        }
    }

    .ProseMirror-gapcursor + .node-imageBlock,
    .ProseMirror-gapcursor + .node-imageUpload,
    .ProseMirror-gapcursor + [data-type='blockquoteFigure'] {
        @apply outline-blue-700 hover:outline-blue-600 dark:outline-blue-300 dark:hover:outline-blue-400;
    }

    [data-type='blockquoteFigure'] > div {
        @apply border-l-4 border-l-blue-700 py-2 px-4 bg-opacity-80 rounded-lg rounded-tl-none rounded-bl-none;
        @apply dark:border-l-blue-300;
    }

    [data-type='blockquoteFigure'] > blockquote {
        @apply text-lg text-black;
        @apply dark:text-white;
    }

    [data-type='blockquoteFigure'] figcaption {
        @apply text-sm text-blue-500 mt-4 transition-all overflow-hidden;
        @apply dark:text-blue-400;
    }
}